<template>
  <div class="d-flex">
    <div
      :id="
        verlaengerungen.beschreibung +
        ' ' +
        verlaengerungen.verfuegbarkeitsStatus +
        ' ' +
        verlaengerungen.reiseterminId
      "
      v-for="verlaengerungen in groupedVerlaengerungen"
      :key="
        verlaengerungen.beschreibung +
        ' ' +
        verlaengerungen.verfuegbarkeitsStatus +
        ' ' +
        verlaengerungen.reiseterminId
      "
    >
      <div class="einheiten-frei rounded" :class="istBgColor(verlaengerungen) + ' mr-1'">
        {{
          verlaengerungen.verfuegbarkeitsStatus == 'Verfuegbar'
            ? 'J'
            : verlaengerungen.verfuegbarkeitsStatus == 'NichtVerfuegbar'
            ? 'N'
            : 'RQ'
        }}
      </div>
      <div class="text-center zimmertyp">
        {{
          verlaengerungen.beschreibung
            .split(' ')
            .map(word => word.substring(0, 1))
            .join('')
        }}
      </div>
      <b-tooltip
        :target="
          verlaengerungen.beschreibung +
          ' ' +
          verlaengerungen.verfuegbarkeitsStatus +
          ' ' +
          verlaengerungen.reiseterminId
        "
        custom-class="custom-tooltip-verlaengerungen-widget"
      >
        <b>{{ verlaengerungen.beschreibung }}</b>
        <div
          class="d-flex mt-2 justify-content-between"
          v-for="(value, key) in verlaengerungen.hotels"
          :key="
            verlaengerungen.beschreibung +
            ' ' +
            verlaengerungen.verfuegbarkeitsStatus +
            ' ' +
            verlaengerungen.reiseterminId +
            ' ' +
            key
          "
        >
          <div class="mr-2">{{ key }} ({{ getKuerzel(key, verlaengerungen.beschreibung) }}):</div>
          <div class="d-flex">
            <div
              v-for="(value, key) in value"
              :key="
                verlaengerungen.beschreibung +
                ' ' +
                verlaengerungen.verfuegbarkeitsStatus +
                ' ' +
                verlaengerungen.reiseterminId +
                ' ' +
                key +
                ' ' +
                value
              "
            >
              <div class="einheiten-frei rounded" :class="istBgColor(verlaengerungen) + ' mr-1'">
                {{
                  verlaengerungen.verfuegbarkeitsStatus == 'Verfuegbar'
                    ? value
                    : verlaengerungen.verfuegbarkeitsStatus == 'NichtVerfuegbar'
                    ? 'N'
                    : 'RQ'
                }}
              </div>
              <div class="text-center zimmertyp">
                {{ key }}
              </div>
            </div>
          </div>
        </div>
      </b-tooltip>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    verlaengerungen: {
      type: Array,
      required: true,
    },
    reiseterminId: {
      type: Number,
    },
  },
  computed: {
    groupedVerlaengerungen() {
      if (this.verlaengerungen) {
        const prefilteredDataAufAnfrage = this.verlaengerungen.filter(
          vl => vl.verfuegbarkeitsStatus === 'AufAnfrage'
        );
        const prefilteredDataVerfuegbar = this.verlaengerungen.filter(
          vl => vl.verfuegbarkeitsStatus === 'Verfuegbar'
        );

        const uniqueBeschreibungenAufAnfrage = [
          ...new Set(prefilteredDataAufAnfrage.map(vl => vl.beschreibung)),
        ];
        const uniqueBeschreibungenVerfuegbar = [
          ...new Set(prefilteredDataVerfuegbar.map(vl => vl.beschreibung)),
        ];
        const finalShapeDataAufAnfrage = uniqueBeschreibungenAufAnfrage.map(beschreibung => {
          return {
            reiseterminId: this.reiseterminId,
            verfuegbarkeitsStatus: 'AufAnfrage',
            beschreibung,
            hotels: [
              ...new Set(
                prefilteredDataAufAnfrage.filter(vl => vl.beschreibung === beschreibung).map(vl => vl.name)
              ),
            ].reduce((acc, currName) => {
              acc[currName] = prefilteredDataAufAnfrage
                .filter(vl => vl.beschreibung === beschreibung && vl.name === currName)
                .reduce((acc, curr) => {
                  acc[curr.zimmertyp] = curr.einheitenFrei;
                  return acc;
                }, {});
              return acc;
            }, {}),
          };
        });
        const finalShapeDataVerfuegbar = uniqueBeschreibungenVerfuegbar.map(beschreibung => {
          return {
            reiseterminId: this.reiseterminId,
            verfuegbarkeitsStatus: 'Verfuegbar',
            beschreibung,
            hotels: [
              ...new Set(
                prefilteredDataVerfuegbar.filter(vl => vl.beschreibung === beschreibung).map(vl => vl.name)
              ),
            ].reduce((acc, currName) => {
              acc[currName] = prefilteredDataVerfuegbar
                .filter(vl => vl.beschreibung === beschreibung && vl.name === currName)
                .reduce((acc, curr) => {
                  acc[curr.zimmertyp] = curr.einheitenFrei;
                  return acc;
                }, {});
              return acc;
            }, {}),
          };
        });
        return [...finalShapeDataAufAnfrage, ...finalShapeDataVerfuegbar]
          .filter(vl => Object.keys(vl.hotels).length > 0)
          .sort((a, b) => {
            const order = { Verfuegbar: 1, AufAnfrage: 2 };
            const orderA = order[a.verfuegbarkeitsStatus];
            const orderB = order[b.verfuegbarkeitsStatus];
            return orderA - orderB;
          });
      } else {
        return [];
      }
    },
  },
  methods: {
    istBgColor(verlaengerungen) {
      return verlaengerungen.verfuegbarkeitsStatus == 'Verfuegbar'
        ? 'green'
        : verlaengerungen.verfuegbarkeitsStatus == 'NichtVerfuegbar'
        ? 'bg-danger'
        : 'gray';
    },
    getKuerzel(name, beschreibung) {
      if (this.verlaengerungen) {
        const kuerzel = this.verlaengerungen.find(
          item => item.name === name && item.beschreibung === beschreibung
        )?.kuerzel;
        return kuerzel;
      } else return '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.bootstrap.scss';

.tooltip {
  opacity: 1 !important;
}
.green {
  background-color: $success;
}
.einheiten-frei {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 30px;
  height: 25px;
  font-size: 1rem;
}
.gray {
  background-color: #e5eaee;
}
</style>
<style>
.custom-tooltip-verlaengerungen-widget > .tooltip-inner {
  max-width: none;
}
</style>
