<template>
  <div :class="horizontal ? 'd-flex justify-content-center align-items-center' : ''">
    <CustomMultiselect
      :placeholder="$t('REISEN.COMMON.KONTINENT')"
      :title="$t('REISEN.COMMON.KONTINENT')"
      group-label="group"
      group-values="continents"
      group-select
      track-by="value"
      id="filter-kontinent"
      label="label"
      :value="selectedRegion"
      :show-labels="false"
      :multiple="true"
      @input="onInputFormSelect('inputRegion', $event || [])"
      :options="optionsRegions"
    ></CustomMultiselect>
    <CustomMultiselect
      :class="horizontal ? 'ml-4' : 'mt-4'"
      :placeholder="$t('REISEN.COMMON.COUNTRY')"
      :title="$t('REISEN.COMMON.COUNTRY')"
      track-by="value"
      label="label"
      id="filter-land"
      :show-labels="false"
      :multiple="true"
      :value="selectedCountry"
      @input="onInputFormSelect('inputCountry', $event || [])"
      :options="optionsCountries"
    ></CustomMultiselect>
  </div>
</template>

<script>
import CustomMultiselect from '@/components/common/custom-multiselect.vue';

export default {
  components: {
    CustomMultiselect,
  },
  props: {
    horizontal: { type: Boolean, default: false },
    valueRegion: { type: Array, default: () => [] },
    valueCountry: { type: Array, default: () => [] },
    countriesGroupedByRegions: { type: Object, default: () => {} },
  },
  data() {
    return {
      regionsCountriesObj: null,
    };
  },
  computed: {
    selectedRegion() {
      return Array.isArray(this.valueRegion) && this.valueRegion.length > 0 ? this.valueRegion : [];
    },
    selectedCountry() {
      return Array.isArray(this.valueCountry) && this.valueCountry.length > 0 ? this.valueCountry : [];
    },
    optionsRegions() {
      return this.regionsCountriesObj || [];
    },
    optionsCountries() {
      // If there is no data returns a empty array
      // If there is data but no region selected (selectedRegion) returns all countries (flatMap)
      // If there is a region selected and data filter data by the selected regions
      // and only return countries within selected regions
      return this.sortCountriesArray(
        this.regionsCountriesObj
          ? Array.isArray(this.selectedRegion) && this.selectedRegion.length > 0
            ? this.selectedRegion
                .map(selectedRegion => selectedRegion.countries)
                .reduce((accumulator, currentValue) => accumulator.concat(currentValue))
            : this.regionsCountriesObj.flatMap(group =>
                group.continents.flatMap(continent => continent.countries)
              )
          : []
      );
    },
  },
  watch: {
    countriesGroupedByRegions: {
      immediate: true,
      handler: async function (v) {
        if (v) {
          this.regionsCountriesObj = this.sortRegionArray(this.convertRegionObjectToArray(v));
        }
      },
    },
  },
  methods: {
    onInputFormSelect(eventType, $event) {
      // Reset country filter if region is selected
      if (eventType === 'inputRegion') {
        this.$emit('inputCountry', []);
      }
      this.$emit(eventType, $event);
    },
    convertRegionObjectToArray(groupedRegionObject) {
      // converts the grouped object into a grouped array of objects
      // and groups them by the indices of groupObj into Fernreisen,Europa,Dach or Sonstige
      // translates values to labels
      // Input: { Region1 : { countries: []}, Region2: {}, ...}
      // Output: [ {group: "Fernreisen", continents : { label: Region1, value: Region1, countries: []},{value: Region2,label: Region2, countries: []}}]
      const groupObj = {
        Asien: 0,
        Orient: 0,
        Amerika: 0,
        Afrika: 0,
        Ozeanien: 0,
        Nordeuropa: 1,
        Südeuropa: 1,
        Dach: 2,
      };
      let regionArray = Object.entries(groupedRegionObject);
      return regionArray.reduce(
        (accumulator, [region, countries]) => {
          const groupIndex =
            groupObj[region] !== null && groupObj[region] !== undefined ? groupObj[region] : 3;
          accumulator[groupIndex].continents.push({
            value: region,
            label: this.$t(`REISEN.REGIONS.${region}`),
            countries: countries.map(country => {
              return {
                value: country.country,
                label: this.$t(`REISEN.COUNTRIES.${country.country}`),
              };
            }),
          });
          return accumulator;
        },
        [
          { group: 'Fernreisen', continents: [] },
          { group: 'Europareisen', continents: [] },
          { group: 'DACH-Reisen', continents: [] },
          { group: 'Sonstige', continents: [] },
        ]
      );
    },
    sortRegionArray(array) {
      // Sorts they array according to the specified order array
      const orderArray = [
        'Asien',
        'Orient',
        'Afrika',
        'Amerika',
        'Ozeanien',
        'Südeuropa',
        'Nordeuropa',
        'DACH',
      ];
      return array.sort((a, b) => {
        const index1 = orderArray.indexOf(a.value);
        const index2 = orderArray.indexOf(b.value);
        return (index1 > -1 ? index1 : Infinity) - (index2 > -1 ? index2 : Infinity);
      });
    },
    sortCountriesArray(array) {
      // Sorts the countries by their labels
      return array.sort((a, b) => a.label.localeCompare(b.label));
    },
  },
};
</script>
