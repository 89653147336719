<template>
  <div class="d-flex justify-content-center text-muted">
    <date-picker
      v-model="selected"
      @input="onInput"
      @calendar-change="onCalChange"
      :shortcuts="shortcuts"
      type="date"
      :lang="langConfig"
      input-class="form-control"
      format="DD.MM.YYYY"
      range-separator="-"
      range
      :disabled-date="disabledDates"
      :placeholder="placeholder"
      ref="datepicker"
      class="w-100"
    >
      <template v-slot:footer="{ emit }">
        <div class="text-center">
          <button
            class="mx-btn mx-btn-text"
            v-for="(month, index) in months"
            :key="'cal-short-btn-month-' + month"
            @click="selectMonth(emit, month, index)"
          >
            {{ month }}
          </button>
        </div>

        <div class="text-center">
          <button
            class="mx-btn mx-btn-text"
            v-for="(year, index) in years"
            :key="'cal-short-btn-year-' + year"
            @click="selectYear(emit, year, index)"
          >
            {{ year }}
          </button>
        </div>
      </template></date-picker
    >
  </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
const moment = require('moment');

export default {
  name: 'DateRangePicker',
  components: { DatePicker },
  props: {
    value: { type: Object },
    placeholder: { type: String, default: '' },
    isWeeklyDaterangePicker: { type: Boolean, default: false },
  },
  data() {
    return {
      selected: null,
      shortcuts: this.isWeeklyDaterangePicker
        ? []
        : [
            {
              text: this.$t('COMPONENT.FILTER.DATE.SHORTCUTS.HEUTE'),
              onClick: () => {
                const date = new Date();
                this.calendarSelectedYear = date.getFullYear();
                return [date, date];
              },
            },
            {
              text: this.$t('COMPONENT.FILTER.DATE.SHORTCUTS.JAHRESENDE'),
              onClick: () => {
                const start = new Date();
                const end = new Date(new Date().getFullYear(), 11, 31);
                this.calendarSelectedYear = start.getFullYear();
                return [start, end];
              },
            },
            {
              text: this.$t('COMPONENT.FILTER.DATE.SHORTCUTS.ANFANGJAHR'),
              onClick: () => {
                const start = new Date(new Date().getFullYear(), 0, 1);
                this.calendarSelectedYear = start.getFullYear();
                return [start, start];
              },
            },
            {
              text: this.$t('COMPONENT.FILTER.DATE.SHORTCUTS.INMONATE'),
              onClick: () => {
                const start = new Date();
                const newDate = new Date();
                const end = new Date(newDate.setMonth(newDate.getMonth() + 3));
                this.calendarSelectedYear = start.getFullYear();
                return [start, end];
              },
            },
          ],
      langConfig: {
        formatLocale: {
          months: moment.localeData().months(),
          monthsShort: moment.localeData().monthsShort(),
          weekdays: moment.localeData().weekdays(),
          weekdaysShort: moment.localeData().weekdaysShort(),
          weekdaysMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
          firstDayOfWeek: 1,
        },
      },
      calendarSelectedYear: new Date().getFullYear(),
    };
  },
  watch: {
    value: {
      immediate: true,
      handler: function (newDate) {
        this.selected = newDate
          ? newDate.start && newDate.end
            ? [new Date(newDate.start), new Date(newDate.end)]
            : newDate.start && !newDate.end
            ? [new Date(newDate.start), new Date(newDate.start)]
            : !newDate.start && newDate.end
            ? [new Date(newDate.end), new Date(newDate.end)]
            : []
          : [];
      },
    },
  },
  computed: {
    months() {
      return Array.apply(0, Array(12)).map(function (_, i) {
        return moment().month(i).format('MMM');
      });
    },
    years() {
      const currentYear = new Date().getFullYear();
      const yearRange = 3;
      return Array.from({ length: yearRange * 2 + 1 }, (_, i) => currentYear - yearRange + i);
    },
  },
  methods: {
    onCalChange(date) {
      this.calendarSelectedYear = date.getFullYear();
    },
    onInput($event) {
      this.$emit(
        'input',
        this.$options.filters.dateNumeric($event[0]),
        $event[0] === $event[1] ? null : this.$options.filters.dateNumeric($event[1])
      );
    },
    selectMonth(emit, month, index) {
      this.$refs.datepicker.closePopup();
      const y = this.calendarSelectedYear,
        m = index;
      const firstDay = new Date(y, m, 1);
      const lastDay = new Date(y, m + 1, 0);
      emit([firstDay, lastDay]);
    },
    selectYear(emit, year) {
      this.$refs.datepicker.closePopup();
      const firstDay = new Date(year, 0, 1);
      const lastDay = new Date(year, 11, 31);
      this.calendarSelectedYear = year;
      emit([firstDay, lastDay]);
    },
    disabledDates(date) {
      const day = new Date(date).getDay();
      if (this.isWeeklyDaterangePicker) return day !== 6;
      else return;
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.form-control) {
  height: calc(1.35em + 1.1rem + 2px);
}
</style>
<style>
input::placeholder {
  color: #adadad !important;
}
</style>
