<template>
  <FlugverfuegbarkeitenWidget
    :flugverfuegbarkeiten="params.value ?? []"
    :data="params.data"
  ></FlugverfuegbarkeitenWidget>
</template>

<script>
import FlugverfuegbarkeitenWidget from '@/components/produkte/reisetermine/flugverfuegbarkeiten-widget.vue';

export default {
  components: { FlugverfuegbarkeitenWidget },
  mounted() {},
};
</script>
