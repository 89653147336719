<template>
  <ZimmerkontingenteWidget
    :zimmerkontingente="params.data?.zimmerkontingente"
    :reiseterminId="params.data?.id"
    :isZvc="params.isZvc"
  ></ZimmerkontingenteWidget>
</template>

<script>
import ZimmerkontingenteWidget from '@/components/common/zimmerkontingente/zimmerkontingente-widget.vue';
export default {
  components: {
    ZimmerkontingenteWidget,
  },
};
</script>
