<template>
  <VerlaengerungWidget
    :zusatzleistungen="zusatzleistungen"
    :reiseterminId="params.data.id"
  ></VerlaengerungWidget>
</template>

<script>
import VerlaengerungWidget from '@/components/produkte/reisetermine/verlangerung-widget.vue';

export default {
  components: { VerlaengerungWidget },
  computed: {
    zusatzleistungen() {
      if (this.params?.data?.zusatzleistungen) {
        return this.params.data.zusatzleistungen;
      }

      return [];
    },
  },
};
</script>
