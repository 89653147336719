<template>
  <div class="p-4" style="display: flex; gap: 8px; background-color: #e5f0fb">
    <!-- LEFT -->
    <div class="p-4 card" style="max-width: 600px; min-width: 400px" v-if="isAfter2024">
      <div class="d-flex align-items-center" style="gap: 8px">
        <h6 class="m-0">Fluginformationen</h6>
        <b-badge
          style="height: 32px; display: flex; justify-content: center; align-items: center"
          :variant="einkaufsart === 'RES GRP' ? 'danger' : ''"
        >
          {{ einkaufsart }}
        </b-badge>
        <b-button class="ml-auto" size="sm" variant="primary" @click="onClickAufpreisRechner">
          Aufpreis-Rechner
        </b-button>
      </div>

      <div class="p-2 mt-3" style="display: flex; gap: 12px">
        <table style="border-radius: 8px">
          <tr v-for="(item, i) in tableDefinition" :key="'tableRow-' + i">
            <td
              v-for="(cell, j) in item"
              :key="'tableRow-' + i + '_tableCell-' + j"
              :style="cell.style"
              :class="cell.class"
            >
              {{ cell.string }}
            </td>
          </tr>
        </table>
      </div>
    </div>

    <!-- RIGHT -->
    <div class="p-4 card" style="min-width: 400px; display: flex; gap: 24px">
      <div>
        <h6 class="m-0">Verlängerungen</h6>
        <VerlaengerungWidget
          class="p-2"
          :verlaengerungen="verlaengerungen"
          :reiseterminId="reiseterminId"
          v-if="verlaengerungen.length > 0"
        ></VerlaengerungWidget>
        <div v-else class="p-2">Keine Verlängerungen vorhanden!</div>
      </div>
      <div>
        <h6 class="m-0">Optionals</h6>
        <OptionalsWidget
          v-if="optionals.length > 0"
          class="p-2"
          :optionals="optionals"
          :reiseterminId="reiseterminId"
        ></OptionalsWidget>
        <div v-else class="p-2">Keine Optionals vorhanden!</div>
      </div>
    </div>
  </div>
</template>
<script>
import { einkaufsartOptions } from '@/core/common/helpers/utils.js';
import VerlaengerungWidget from '@/components/produkte/reisetermine/verlangerung-widget.vue';
import OptionalsWidget from './optionals-widget.vue';
import { format, set, add, isAfter } from 'date-fns';

export default {
  components: { VerlaengerungWidget, OptionalsWidget },
  data() {
    return {
      data: null,
      tableDefinition: null,
    };
  },
  mounted() {
    this.data = this.params.data;

    const commonTableDefinition = [
      [
        { string: '', style: '', class: '' },
        { string: 'Hinflug', style: 'font-weight: bold', class: '' },
        { string: 'Rückflug', style: 'font-weight: bold', class: '' },
      ],
      [
        { string: 'ZL Zielflughafen / Abflughafen', style: '', class: 'row-description' },
        { string: this.ziellandZielflughaefen, style: '', class: '' },
        { string: this.ziellandAbflughaefen, style: '', class: '' },
      ],
      [
        { string: 'Früh. Ankunft / Abflug', style: '', class: 'row-description' },
        { string: this.fruehesteAnkunftDateFormatted, style: '', class: '' },
        { string: this.fruehesterAbflugDateFormatted, style: '', class: '' },
      ],
      [
        { string: 'Spät. Ankunft / Abflug', style: '', class: 'row-description' },
        { string: this.spaetesteAnkunftDateFormatted, style: '', class: '' },
        { string: this.spaetesterAbflugDateFormatted, style: '', class: '' },
      ],

      [
        { string: 'TF Szenario', style: '', class: 'row-description' },
        { string: this.transferszenarioHin, style: '', class: '' },
        { string: this.transferszenarioRueck, style: '', class: '' },
      ],
    ];

    if (this.isNotEp) {
      this.tableDefinition = [
        ...commonTableDefinition,
        [
          { string: 'GRP FH', style: '', class: 'row-description' },
          { string: this.gruppeFlughafen, style: '', class: '' },
          { string: '', style: '', class: '' },
        ],
        [
          { string: 'Flugnr. GRP', style: '', class: 'row-description' },
          { string: this.flugnummerGruppeHin, style: '', class: '' },
          { string: this.flugnummerGruppeRueck, style: '', class: '' },
        ],
        [
          { string: 'GRP PNR', style: '', class: 'row-description' },
          { string: this.gruppePnr, style: '', class: '' },
          { string: '', style: '', class: '' },
        ],
      ];
    } else {
      this.tableDefinition = [
        ...commonTableDefinition,
        [
          { string: 'Vorverlängerung', style: '', class: 'row-description' },
          { string: this.vorverlaengerung, style: '', class: '' },
          { string: '', style: '', class: '' },
        ],
      ];
    }
  },
  computed: {
    isAfter2024() {
      if (this.data) {
        const isAfter2024 = isAfter(new Date(this.data.startdatum), new Date('2024-12-31'));
        return isAfter2024;
      }
      return false;
    },
    reiseterminId() {
      return this.data?.id ?? null;
    },
    verlaengerungen() {
      if (this?.data?.zusatzleistungen) {
        return this.data.zusatzleistungen.filter(
          vl => !vl.isDeleted && vl.typ === 'Verlaengerung' && vl.verfuegbarkeitsStatus !== 'NichtVerfuegbar'
        );
      }
      return [];
    },
    optionals() {
      if (this?.data?.zusatzleistungen) {
        return this.data.zusatzleistungen.filter(
          optional =>
            !optional.isDeleted &&
            optional.typ === 'Optional' &&
            optional.verfuegbarkeitsStatus !== 'NichtVerfuegbar'
        );
      }
      return [];
    },
    einkaufsart() {
      if (!this.data || !this.data.flugeinkauf) {
        return 'EP';
      }
      const resGrpEnums = ['RestriktivBestaetigtBuchbar', 'LeerverkaufRes', 'RestriktivStattBackup'];
      const flexGrpEpEnums = ['FlexBuchbar', 'LeerverkaufFlex'];

      return resGrpEnums.includes(this.data.flugeinkauf.einkaufsart)
        ? 'RES GRP'
        : flexGrpEpEnums.includes(this.data.flugeinkauf.einkaufsart)
        ? 'FLEX GRP / EP'
        : 'EP';
    },
    vorverlaengerung() {
      if (!this.data || !this.data.metadata) return '';

      return this.data.metadata.vorverlaengerung;
    },
    ziellandAbflughaefen() {
      if (!this.data || !this.data.metadata?.ziellandAbflughaefen) return '-';

      return this.data.metadata.ziellandAbflughaefen.map(fh => fh.flughafenIataCode).join(', ');
    },
    ziellandZielflughaefen() {
      if (!this.data || !this.data.metadata?.ziellandZielflughaefen) return '-';

      return this.data.metadata.ziellandZielflughaefen.map(fh => fh.flughafenIataCode).join(', ');
    },
    fruehesteAnkunftDate() {
      if (!this.data || !this.data.metadata?.fruehesteAnkunft) return null;

      const { startdatum } = this.data;
      const { fruehesteAnkunft } = this.data.metadata;

      const date = new Date(startdatum);
      const time = { hours: fruehesteAnkunft.substring(0, 2), minutes: fruehesteAnkunft.substring(3, 5) };

      return set(date, time);
    },
    fruehesteAnkunftDateFormatted() {
      if (!this.fruehesteAnkunftDate) return '-';

      return format(this.fruehesteAnkunftDate, 'dd.MM.yyyy HH:mm');
    },
    fruehesterAbflugDate() {
      if (!this.data || !this.data.metadata?.fruehesterAbflug) return null;
      const { hotelendUndRueckflugdatum } = this.data;
      const { fruehesterAbflug } = this.data.metadata;

      const date = new Date(hotelendUndRueckflugdatum);
      const time = { hours: fruehesterAbflug.substring(0, 2), minutes: fruehesterAbflug.substring(3, 5) };

      return set(date, time);
    },
    fruehesterAbflugDateFormatted() {
      if (!this.fruehesterAbflugDate) return '-';

      return format(this.fruehesterAbflugDate, 'dd.MM.yyyy HH:mm');
    },
    spaetesteAnkunftDate() {
      if (!this.data || !this.data.metadata?.spaetesteAnkunft) return null;
      const { startdatum } = this.data;
      const { spaetesteAnkunft, restriktionenHinRollover } = this.data.metadata;

      const date = new Date(startdatum);
      const time = { hours: spaetesteAnkunft.substring(0, 2), minutes: spaetesteAnkunft.substring(3, 5) };

      return add(set(date, time), { days: restriktionenHinRollover ?? 0 });
    },
    spaetesteAnkunftDateFormatted() {
      if (!this.spaetesteAnkunftDate) return '-';

      return format(this.spaetesteAnkunftDate, 'dd.MM.yyyy HH:mm');
    },
    spaetesterAbflugDate() {
      if (!this.data || !this.data.metadata?.spaetesterAbflug) return null;
      const { hotelendUndRueckflugdatum } = this.data;
      const { spaetesterAbflug, restriktionenRueckRollover } = this.data.metadata;

      const date = new Date(hotelendUndRueckflugdatum);
      const time = { hours: spaetesterAbflug.substring(0, 2), minutes: spaetesterAbflug.substring(3, 5) };

      return add(set(date, time), { days: restriktionenRueckRollover ?? 0 });
    },
    spaetesterAbflugDateFormatted() {
      if (!this.spaetesterAbflugDate) return '-';

      return format(this.spaetesterAbflugDate, 'dd.MM.yyyy HH:mm');
    },
    transferszenarioHin() {
      if (!this.data || !this.data.metadata?.transferszenarioHin) return '-';
      return this.data.metadata.transferszenarioHin;
    },
    transferszenarioRueck() {
      if (!this.data || !this.data.metadata?.transferszenarioRueck) return '-';
      return this.data.metadata.transferszenarioRueck;
    },
    isNotEp() {
      return this.einkaufsart !== 'EP';
    },
    gruppeFlughafen() {
      if (!this.data || !this.data.flugeinkauf) return '';

      return this.data.flugeinkauf.gruppeFlughafenIataCode;
    },
    flugnummerGruppeHin() {
      if (!this.data || !this.data.flugeinkauf) return '';

      return this.data.flugeinkauf.flugnummerGruppeHin;
    },
    flugnummerGruppeRueck() {
      if (!this.data || !this.data.flugeinkauf) return '';

      return this.data.flugeinkauf.flugnummerGruppeRueck;
    },
    gruppePnr() {
      if (!this.data || !this.data.flugeinkauf) return '';

      return this.data.flugeinkauf.gruppePnr;
    },
  },

  methods: {
    onClickAufpreisRechner(event) {
      event.preventDefault();
      const routeData = this.$router.resolve({ path: '/aufpreis-rechner?reiseterminId=' + this.data.id });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>

<style scoped>
td {
  padding: 4px 8px;
  width: 150px;
}
tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.row-description {
  background-color: #e5eaee;
}
</style>
