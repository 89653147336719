<template>
  <OptionalsWidget :zusatzleistungen="zusatzleistungen" :reiseterminId="reiseterminId"></OptionalsWidget>
</template>

<script>
import OptionalsWidget from '@/components/produkte/reisetermine/optionals-widget.vue';

export default {
  components: { OptionalsWidget },

  computed: {
    zusatzleistungen() {
      if (this.params?.data?.zusatzleistungen) {
        return this.params.data.zusatzleistungen;
      }
      return [];
    },
    reiseterminId() {
      return this.params?.data?.id ?? null;
    },
  },
};
</script>
