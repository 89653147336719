var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},_vm._l((_vm.groupedOptionals),function(optionals){return _c('div',{key:optionals.beschreibung + ' ' + optionals.verfuegbarkeitsStatus + ' ' + optionals.reiseterminId,attrs:{"id":optionals.beschreibung + ' ' + optionals.verfuegbarkeitsStatus + ' ' + optionals.reiseterminId}},[_c('div',{staticClass:"einheiten-frei rounded",class:_vm.istBgColor(optionals) + ' mr-1'},[_vm._v(" "+_vm._s(optionals.verfuegbarkeitsStatus == 'Verfuegbar' ? 'J' : optionals.verfuegbarkeitsStatus == 'NichtVerfuegbar' ? 'N' : 'RQ')+" ")]),_c('div',{staticClass:"text-center zimmertyp"},[_vm._v(" "+_vm._s(optionals.beschreibung .split(' ') .map(word => word.substring(0, 1)) .join(''))+" ")]),_c('b-tooltip',{attrs:{"target":optionals.beschreibung + ' ' + optionals.verfuegbarkeitsStatus + ' ' + optionals.reiseterminId,"custom-class":"custom-tooltip-optionals-widget"}},[_c('b',[_vm._v(_vm._s(optionals.beschreibung))]),_vm._l((optionals.hotels),function(value,key){return _c('div',{key:optionals.beschreibung +
          ' ' +
          optionals.verfuegbarkeitsStatus +
          ' ' +
          optionals.reiseterminId +
          ' ' +
          key,staticClass:"d-flex mt-2 justify-content-between"},[_c('div',{staticClass:"mr-2"},[_vm._v(_vm._s(key)+" ("+_vm._s(_vm.getKuerzel(key, optionals.beschreibung))+"):")]),_c('div',{staticClass:"d-flex"},_vm._l((value),function(value,key){return _c('div',{key:optionals.beschreibung +
              ' ' +
              optionals.verfuegbarkeitsStatus +
              ' ' +
              optionals.reiseterminId +
              ' ' +
              key +
              ' ' +
              value},[_c('div',{staticClass:"einheiten-frei rounded",class:_vm.istBgColor(optionals) + ' mr-1'},[_vm._v(" "+_vm._s(optionals.verfuegbarkeitsStatus == 'Verfuegbar' ? value : optionals.verfuegbarkeitsStatus == 'NichtVerfuegbar' ? 'N' : 'RQ')+" ")]),_c('div',{staticClass:"text-center zimmertyp"},[_vm._v(" "+_vm._s(key)+" ")])])}),0)])})],2)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }