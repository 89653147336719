<template>
  <div>
    <ActionButton
      :link="'https://skr.de/+' + params.data.reise.reisekuerzel"
      title="Reisewiki"
      iconClass="fab fa-wikipedia-w"
    />
    <ActionButton
      :link="{ name: 'reisetermine-view', params: { reiseterminId: params.data.id } }"
      title="Reisetermin Detailansicht"
      iconClass="fas fa-info"
    />
    <ActionButton
      :link="
        'https://www.skr.de/reiseinfos/' +
        params.data.reiseterminkuerzel.replaceAll('-', '').replaceAll('_', '') +
        '/L/'
      "
      title="Reiseunterlagen"
      iconClass="flaticon-book text-black"
    />
  </div>
</template>

<script>
import ActionButton from '@/components/common/action-button.vue';

export default {
  components: { ActionButton },
};
</script>

<style lang="scss" scoped></style>
